<template>
    <div>
        <b-card>
            <template v-slot:header>
                <h5>Arsip Selisih Barang</h5>
            </template>
            <b-table bordered hover show-empty :busy="isLoading" :items="daftarSelisih" :fields="fields" responsive
                class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading data...</strong>
                    </div>
                </template>
                <template v-slot:cell(action)="row">
                    <b-button @click="openModalSelisih(row.item)" size="sm" variant="pgiBtn">
                        Detail
                    </b-button>
                </template>

                <template v-slot:cell(supplier)="row">
                    <div>
                        <small>
                            <b>{{ row.item.supplier.nama }}</b>
                        </small>
                        <small class="d-block">
                            <b v-if="row.item.is_online === 1">{{ row.item.shop_name }}</b>
                            <b v-if="row.item.is_online === 2">{{ row.item.supplier.no_hp }}</b>
                        </small>
                    </div>
                </template>
                <template v-slot:cell(no_invoice)="row">
                    <div>
                        <small class="d-block">
                            <b>{{ row.item.no_invoice }}</b>
                        </small>
                        <small class="d-block">
                            <b>{{ row.item.tanggal }}</b>
                        </small>
                    </div>
                </template>
                <template v-slot:cell(total_dana)="row">
                    <div class="d-flex justify-content-between">
                        <div>Rp.</div>
                        <span>{{ $helper.rupiah(row.item.total_dana) }}</span>
                    </div>
                </template>
            </b-table>
            <div class="pagination-wrapper d-flex align-items-center justify-content-end">
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>

        <b-modal id="m-detail" size="lg" title="Detail Daftar Selisih" no-close-on-esc hide-footer>
            <ul class="list-group list-group-flush">
                <li v-for="item in itemSelected" :key="item.id" class="list-group-item p-1">
                    <div class="row">
                        <div class="col-md-8">
                            <div>{{ item.nama_barang }}</div>
                            <small>Jumlah Selisih : {{ item.total_item }} Pcs</small>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <h6 class="text-end">Rp {{ $helper.rupiah(item.total_dana || 0) }}</h6>
                        </div>
                    </div>
                </li>
            </ul>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from '../../../config/api';

export default {
    data() {
        return {
            isLoading: false,
            daftarSelisih: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: '',
            size: '',
            from: '',
            limit: '',
            query: "",
            itemSelected: [],
            fields: [
                { key: "no_invoice", label: "Invoice" },
                { key: "supplier", label: "Supplier" },
                { key: "total_item", label: "Total Selisih" },
                { key: "total_dana", label: "Total Dana Selisih" },
                { key: "action", label: "Aksi" },
            ],
        }
    },
    methods: {
        async getHistorySelisih() {
            try {
                this.isLoading = true
                const response = await axiosClient({
                    url: 'transaksi/refund/history-selisih',
                    params: {
                        order_by: 'DESC'
                    }
                })
                if (response.status === 200) {
                    const dt = response.data.data
                    this.currentPage = dt.current_page
                    this.perPage = dt.per_page
                    this.rows = dt.total
                    this.page = dt.total
                    this.daftarSelisih = response.data.data.data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }

        },
        openModalSelisih(data) {
            this.itemSelected = data.detail_refund
            this.$bvModal.show("m-detail")
        },
        async onPageChange() {
            console.log("JOS")
        }
    },
    mounted() {
        this.getHistorySelisih()
    }
}
</script>